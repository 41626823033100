import { createAction } from 'redux-actions';
import { loggedIn } from '../../authentication';
// import apiRequest from '../../utils/apiRequest';
// import tokenSelector from '../../utils/tokenSelector';
// import { push } from 'connected-react-router';

const request = createAction('LOAD_RECENT_PROJECTS_REQUEST');
const success = createAction('LOAD_RECENT_PROJECTS_SUCCESS');
const failure = createAction('LOAD_RECENT_PROJECTS_FAILURE');

const defaultList = [
  { id: '1', name: 'New bridge', location: 'Hammersmith', description: 'Looking for an architect to design a bridge' },
  { id: '2', name: 'Sky tower', location: 'Canary Wharf', description: 'The tallest tower in London' },
  { id: '3', name: 'Shopping centre', location: 'Canary Wharf', description: 'A new shopping centre in Canary Wharf' },
  { id: '4', name: 'North city', location: 'North London', description: 'A new residential area' },
  { id: '5', name: 'Thames bridge', location: 'Canary Wharf', description: 'Bridging in the Canary Wharf area' },
];

const loadRecentProjects = (data) => (dispatch, getState) => {
  dispatch(request);
  dispatch(success(defaultList));

  // apiRequest({ path: 'tokens', token: tokenSelector(getState()) , data })
  //   .then((response) => {
  //     dispatch(success(response));
  //     dispatch(push(`/tokens/${response.id}`));
  //   })
  //   .catch(error => dispatch(failure(error)));
};

const signedIn = (user) => (dispatch, getState) => {
  if (user) {
    dispatch(loggedIn(user));
  }
};

export {
  signedIn,
  loadRecentProjects, 
  request, 
  success, 
  failure 
};