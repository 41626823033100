import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    flexGrow: 1,
    height: '100vh',
  },
  heroContent: {
    margin: '0 auto',
    top: '20%',
    position: 'relative',
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit * 8,
    },
    ['@media (max-height:450px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: theme.spacing.unit,
    },
  },
});

const NotFound = ({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.heroContent}>
        <Typography variant="h3" align="center" paragraph>
          Oups!
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          The page you are looking couldn't be found
        </Typography>
        <Grid container spacing={16} justify="center" className={classes.heroButtons}>
          <Grid item>
            <Button variant="contained" color="primary" size="large" component={Link} to="/">
              Makers home
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
