import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    padding: '15px',
    backgroundColor: theme.palette.background.paper,
  },
  sectionTitle: {
    padding: '20px',
  },
});

function HowItWorks({ id = 'how-it-works', classes }) {

  return (
    <div id={id} className={classes.container}>
      <Typography variant="h5" paragraph align="center" className={classes.sectionTitle}>
        How it works
      </Typography>
      <Typography variant="body1" align="center">
        Create an account and you are good to go <span role="img" aria-label="thumbs up">👍</span>
      </Typography>
      <Typography variant="body1" align="center">
        Now you can browse existing projects and apply for them
      </Typography>
      <Typography variant="body1" align="center">
        You want to create a project ? check our Pro and Business accounts
      </Typography>
    </div>
  );
}

HowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
};

export default withStyles(styles)(HowItWorks);