import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import background from '../assets/background_1x.jpg';

const styles = theme => ({
  heroUnit: {
    flexGrow: 1,
    // height: '100vh',
    backgroundImage: `linear-gradient(#000000a6, #000000a6), url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  heroContent: {
    margin: '0 auto',
    // paddingTop: '20vh',
    position: 'relative',
    padding: '60px',
    // top: '30%',
    // [theme.breakpoints.up('md')]: {
    //   top: '20%',
    // },
    // ['@media (max-height:450px)']: { // eslint-disable-line no-useless-computed-key
    //   top: '5%',
    // }
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit * 8,
    },
    ['@media (max-height:450px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: theme.spacing.unit,
    }
  },
  title: {
    color: theme.palette.background.default,
    // fontWeight: 'bold',
  },
  subtitle: {
    color: theme.palette.background.default,
    maxWidth: '90%',
    margin: '0 auto',
  },
  grow: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  menuButton: {
    color: theme.palette.background.default,
  },
  // logo: {
  //   height: '2em',
  //   marginRight: theme.spacing.unit,
  // },
});

function Title({ className, width }) {
  let variant = 'h5';

  if (isWidthUp('sm', width)) {
    variant = 'h3';
  }
  
  return (
    <Typography variant={variant} align="center" paragraph className={className}>
      Your perfect team only a few clicks away
    </Typography>
  );
}

function Subtitle({ className, width }) {
  let variant = 'h5';

  if (isWidthUp('sm', width)) {
    variant = 'h4';
  }
  
  return (
    <Typography variant={variant} align="center" paragraph className={className}>
      We bring construction professionals together
    </Typography>
  );
}

const HeroTitle = withWidth()(Title);
const HeroSubtitle = withWidth()(Subtitle);

function Hero(props) {
  const { classes, loggedIn } = props;
  const gettingStartedLink = loggedIn ? '/dashboard' : '/login';

  return (
    <React.Fragment>
      <div className={classes.heroUnit}>
        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
          <Toolbar>
            {/* <Logo className={classes.logo} /> */}
            <Typography variant="h5" color="primary" className={classes.grow} component={Link} to="/">
              Makers
            </Typography>
            <Button color="primary" className={classes.menuButton} component="a" href="#how-it-works">How it works</Button>
            <Button color="primary" className={classes.menuButton} component="a" href="#pricing">Pricing</Button>
            <Button color="primary" className={classes.menuButton} component={Link} to="/about">About</Button>
            {loggedIn ? (
              <Button variant="contained" color="primary" component={Link} to="/dashboard">Dashboard</Button>
            ) : (
              <Button color="primary" className={classes.menuButton} component={Link} to="/login">Login</Button>
            )
            }
          </Toolbar>
        </AppBar>
        <div className={classes.heroContent}>
          <HeroTitle className={classes.title} />
          <HeroSubtitle className={classes.subtitle} />
          <Grid container spacing={16} justify="center" className={classes.heroButtons}>
            <Grid item>
              <Button variant="contained" color="primary" size="large" component={Link} to={gettingStartedLink}>
                Get started
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hero);