import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Project from './Project';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

function Projects({ classes, list = [] }) {
  return (
    <Grid container spacing={16}>
      {list.map((project) => (
        <Grid item xs={12} sm={6} md={4} key={project.id}>
          <Project {...project} />
        </Grid>
      ))}
    </Grid>
  );
}

Projects.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array,
};

export default withStyles(styles)(Projects);