import { loggedIn, loggedOut } from '../../authentication';
import history from '../../history';

const signedIn = (user) => (dispatch, getState) => {
  dispatch(loggedIn(user));
  history.push('/');
};

const signedOut = (user) => (dispatch, getState) => {
  dispatch(loggedOut(user));
  history.push('/');
};

export { signedIn, signedOut };
