import React from 'react';
import { connect } from 'react-redux';
import Page from '../../containers/Page';
import { signedIn } from './action';
import firebaseApp, { firebase } from '../../firebaseApp';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.signedIn = props.signedIn.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
      this.signedIn(user);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {    
    return (
      <Page withLoginLogoutButton={false}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Page>
    );
  }
}

export default connect(null, { signedIn })(LoginPage);
