import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import CssBaseline from '@material-ui/core/CssBaseline';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    // overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
});

const PageLayout = ({ classes, children, user, withMenu = true, withLoginLogoutButton = true }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <NavBar withLoginLogoutButton={withLoginLogoutButton} user={user}/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          { children }
        </main>
      </div>
    </React.Fragment>
  )
}

PageLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
  withMenu: PropTypes.bool,
  withLoginLogoutButton: PropTypes.bool,
}

export default withStyles(styles)(PageLayout);
