import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 150,
  },
});

const Member = ({ classes, name, image, companyRole, description, linkedin }) => (
  <Card className={classes.card}>
    <CardActionArea>
      <CardMedia
        className={classes.media}
        image={image}
        title={name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Typography gutterBottom variant="h6">
          {companyRole}
        </Typography>
        <Typography component="p">
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      {linkedin ? (
        <Button size="small" color="primary" component={Link} to={linkedin}>
          LinkedIn
        </Button>
      ) : undefined }
    </CardActions>
  </Card>
);

Member.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  companyRole: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
};

export default withStyles(styles)(Member);
