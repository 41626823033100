import firebase from 'firebase/app';
import 'firebase/auth';

// Configure Firebase.
const config = {
  apiKey: "AIzaSyAhgki_zudgb7NlR6-yjlORLZ18mwygDQk",
  authDomain: "kawatta-makers.firebaseapp.com",
  projectId: "kawatta-makers",
};

const firebaseApp = firebase.initializeApp(config);

export { firebase };
export default firebaseApp;
