import { combineReducers } from 'redux';
import { reducer as authentication } from './authentication';
import landing from './pages/Landing/reducer';

const reducers = combineReducers({
  authentication,
  landing,
});

export default reducers;
