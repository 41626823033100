import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import Divider from '@material-ui/core/Divider';
// import { Link } from 'react-router-dom';
import StarIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  // card: {
  //   width: 300,
  // },
  // buttons: {
  //   justifyContent: 'center',
  // },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});

// const PricingPlan = ({ classes, name, cost, features = [] }) => (
  //   <Card className={classes.card}>
  //     <CardActionArea>
  //       <CardContent>
  //         <Typography gutterBottom variant="h5" component="h2">
  //           {name}
  //         </Typography>
  //         <Typography gutterBottom variant="h6">
  //           {cost}
  //         </Typography>
  //         <Divider />
  //         <Typography component="p">
  //           {features}
  //         </Typography>
  //       </CardContent>
  //     </CardActionArea>
  //     <CardActions className={classes.buttons}>
  //       <Button size="large" variant="contained" color="primary" component={Link} to="/subscription?plan=name">
  //         Select
  //       </Button>
  //     </CardActions>
  //   </Card>
  // );
  const PricingPlan = ({ classes, title, subheader, price, description, buttonVariant, buttonText }) => (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={title === 'Pro' ? <StarIcon /> : null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h2" variant="h3" color="textPrimary">
            £{price}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            /mo
          </Typography>
        </div>
        {description.map(line => (
          <Typography variant="subtitle1" align="center" key={line}>
            {line}
          </Typography>
        ))}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button fullWidth variant={buttonVariant} color="primary">
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );

PricingPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  // name: PropTypes.string.isRequired,
  // cost: PropTypes.string.isRequired,
  // features: PropTypes.array,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  price: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default withStyles(styles)(PricingPlan);
