import { handleActions } from 'redux-actions';
import { request, success, failure } from './action';

const reducer = handleActions({
  [request]: (state, action) => ({
    pending: true,
    error: null,
  }),
  [success]: (state, action) => ({
    pending: false,
    error: null,
    recentProjects: action.payload || [],
  }),
  [failure]: (state, action) => ({
    pending: false,
    error: action.payload.message,
  }),
}, {
  pending: false,
  error: null,
  recentProjects: [],
});

export default reducer;
