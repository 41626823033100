import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PricingPlan from './PricingPlan';

const styles = theme => ({
  container: {
    padding: '15px',
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  sectionTitle: {
    padding: '20px',
  },
});

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Browse latest projects',
      'Apply for 1 project'
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      'All Free plan features',
      'Create projects',
      'Compare application',
      'Apply for 5 projects'
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Business',
    price: '25',
    description: [
      'All Pro plan features',
      'Create organisations',
      'Apply for 15 projects',
      'More insights into applications'
    ],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  // {
  //   title: 'Entreprise',
  // }
];

function Pricing({ id = 'pricing', classes }) {
  return (
    <div id={id} className={classes.container}>
      <Typography variant="h5" paragraph align="center" className={classes.sectionTitle}>
        Plans
      </Typography>
      <Grid container spacing={40} alignItems="flex-end">
        {tiers.map(tier => (
        <Grid item key={tier.title} xs={12} sm={tier.title === 'Pro' ? 12 : 6} md={4}>
          <PricingPlan {...tier}/>
        </Grid>
        ))}
      </Grid>
    </div>
  );
}

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
};

export default withStyles(styles)(Pricing);