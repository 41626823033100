import React from 'react';
import { connect } from 'react-redux';
import { signedOut } from './action';

class LogoutPage extends React.Component {
  componentWillMount() {
    this.props.signedOut();
  }

  render() {    
    return null;
  }
}

export default connect(null, { signedOut })(LogoutPage);