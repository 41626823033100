import React from 'react';
import Page from '../../containers/Page';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Member from '../../components/Member';

const styles = theme => ({
  heroContent: {
    margin: '0 auto',
  },
});

function About({ classes }) {
  return (
    <Page withLoginLogoutButton={false}>
      <div className={classes.heroContent}>
        <Typography variant="h6" align="center" paragraph>
          Our mission is to bring construction professionals together and make collaboration easier and more transparent
        </Typography>
        <Divider />
        <Typography variant="body2" align="center" paragraph>
          The team
        </Typography>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} sm={6} md={4}>
            <Member name="First" image="http://via.placeholder.com/150" companyRole="CEO" description="History + job" linkedin="#" className={classes.card} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Member name="Second" image="http://via.placeholder.com/150" companyRole="COO" description="History + job" linkedin="#" className={classes.card} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Member name="Third" image="http://via.placeholder.com/150" companyRole="CTO" description="History + job" linkedin="#" className={classes.card} />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
