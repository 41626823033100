import { createAction, handleActions } from 'redux-actions';

const loggedIn = createAction('AUTHENTICATION_LOGGEDIN');
const loggedOut = createAction('AUTHENTICATION_LOGGEDOUT');

const reducer = handleActions({
  [loggedIn]: (state, action) => ({
    loggedIn: true,
    id: action.payload.uid,
    displayName: action.payload.displayName,
    email: action.payload.email,
    emailVerified: action.payload.emailVerified,
    photoURL: action.payload.photoURL,
  }),
  [loggedOut]: (state, action) => ({
    loggedIn: false,
    id: null,
    displayName: null,
    email: null,
    emailVerified: null,
    photoURL: null,
  }),
}, {
  loggedIn: false,
  id: null,
  displayName: null,
  email: null,
  emailVerified: null,
  photoURL: null,
});

export {
  loggedIn,
  loggedOut,
  reducer,
};