import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Section from './Section';
// import blueGrey from '@material-ui/core/colors/blueGrey';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    // backgroundColor: blueGrey[900],
    // padding: '15px',
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,

  },
  text: {
    // color: theme.palette.background.default,
    textAlign: 'center',
  },
});

function Footer(props) {
  const { classes } = props;
  const year = new Date().getFullYear();

  return (
    <Section extraClasses={classes.container}>
      <Typography variant="subtitle1" align="center" className={classes.text}>
      © Makers {year}
      </Typography>
    </Section>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);