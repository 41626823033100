import history from './history';
import reducers from './reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

const initialState = {};

const store = createStore(
  connectRouter(history)(reducers), // new root reducer with router state
  initialState,
  compose(
    applyMiddleware(
      thunk,
      routerMiddleware(history), // for dispatching history actions
    ),
  ),
);

export default store;
