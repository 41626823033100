import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';

const styles = theme => ({

});

class NavbarProfileMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { name } = this.props;

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
        >
          {name}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem component={Link} to="/account" onClick={this.handleClose}>My account</MenuItem>
          <MenuItem component={Link} to="/logout" onClick={this.handleClose}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

NavbarProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
};

export default withStyles(styles)(NavbarProfileMenu);