import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NavbarProfileMenu from './NavbarProfileMenu';
import { Link } from 'react-router-dom';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  navBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  titleLink: {
    textDecoration: 'none',
    color: 'white'
  }
});

const NavBar = ({ classes, user, withLoginLogoutButton = true }) => {
  let loginLogoutButtons = undefined

  if (withLoginLogoutButton) {
    loginLogoutButtons = user && user.token ? <NavbarProfileMenu name={user.id} /> : <Button component={Link} to="/login" color="inherit">Login</Button>;
  }

  return (
    <React.Fragment>
    <AppBar position="fixed" className={classes.navBar}>
      <Toolbar>
        <Link to="/" className={classes.titleLink}>
          <Typography variant="h6" color="inherit" noWrap>
            Makers
          </Typography>
        </Link>
        <div className={classes.grow} />
        {loginLogoutButtons}
      </Toolbar>
    </AppBar>
  </React.Fragment>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  withLoginLogoutButton: PropTypes.bool,
};

export default withStyles(styles)(NavBar);
