import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hero from '../../components/Hero';
import RecentProjects from '../../components/RecentProjects';
import HowItWorks from '../../components/HowItWorks';
import Pricing from '../../components/Pricing';
import Footer from '../../components/Footer';
import { connect } from 'react-redux';
import { loadRecentProjects, signedIn } from './action';
import firebaseApp from '../../firebaseApp';

const mapStateToProps = ({ landing = {}, authentication = {} }) => Object.assign({ loggedIn: authentication.loggedIn }, landing);

class Landing extends React.Component {
  componentWillMount() {
    this.props.loadRecentProjects();
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
      this.props.signedIn(user);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { recentProjects, loggedIn } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Hero loggedIn={loggedIn} />
        <RecentProjects list={recentProjects} />
        <HowItWorks />
        <Pricing />
        <Footer />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, { loadRecentProjects, signedIn })(Landing);