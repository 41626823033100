import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Projects from './Projects';
import { Link } from 'react-router-dom';

const styles = theme => ({
  container: {
    padding: '15px',
  },
  sectionTitle: {
    padding: '20px',
  },
  buttons: {
    padding: '20px',
    textAlign: 'center',
  },
});

function RecentProjects({ classes, list }) {

  return (
    <div className={classes.container}>
      <Typography variant="h5" paragraph align="center" className={classes.sectionTitle}>
        Newest projects
      </Typography>
      <Projects list={list} />
      <div className={classes.buttons}>
        <Button size="large" variant="contained" color="primary" component={Link} to="/projects">
          Explore all projects
        </Button>
      </div>
    </div>
  );
}

RecentProjects.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array,
};

export default withStyles(styles)(RecentProjects);