import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Route, Switch }  from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from './history';
import store from './store';
import NotFound from './components/NotFound';
import About from './pages/About/index';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import Landing from './pages/Landing/index';
import Login from './pages/Authentication/Login';
import Logout from './pages/Authentication/Logout';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Landing}/>
    <Route path="/about" component={About}/>
    <Route path="/login" component={Login}/>
    <Route path="/logout" component={Logout}/>
    <Route path="*" component={NotFound}/>
  </Switch>
);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: amber,
  },
});

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Analytics
ReactGA.initialize('UA-129001877-2');
ReactGA.pageview(window.location.pathname + window.location.search);
